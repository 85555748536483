import React from "react"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Actions from "../components/actions"

export default () => (
  <Layout>
    <Hero
      title={["Get the help you need while you focus on what you do."]}
      text={[
        "I help people and busineses transition from slow outdated websites to modern fast web apps.",
      ]}
    />
    <Actions
      text={[
        "Begin by sending me an email to",
        <br />,
        "👉glen@glenmossong.co.nz",
      ]}
      goTo=""
      linkTo="mailto:glen@glenmossong.co.nz"
      buttonText="Email me for help"
    />
  </Layout>
)
